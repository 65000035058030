<template>
  <div
    class="comp-battle-item"
    :class="{
      'list-item-ing': item.status === 1,
      'list-item-end': item.status === 2,
    }">
    <div class="item-header flex-b">
      <div
        class="left-text"
        :class="{
          'left-text-end': battleTypeFilterList[item.type].value === '2',
        }">
        {{ battleTypeFilterList[item.type].name }}
      </div>
      <div class="right-text">{{ item.box_num }}回合</div>
    </div>
    <div style="padding: 0 0.05rem" class="foo-wrap">
      <div class="item-name">{{ item.user_name }}</div>
      <div class="player-list flex-a">
        <div
          v-for="vItem in item.newSeatList"
          :key="vItem?.id"
          class="player-item">
          <div v-if="vItem" class="player-item-img">
            <img class="wh100" :src="vItem?.user?.avatar" alt="" srcset="" />
            <div class="float-bg-img"></div>
          </div>
          <div v-else class="player-item-img">
            <img
              class="wh100"
              src="../../../assets/newImages/join-battle-icon.png"
              alt=""
              srcset="" />
          </div>
          <div
            v-if="item?.win_user_id?.includes(vItem.user_id)"
            class="float-win-icon"></div>
        </div>
      </div>
      <div class="box-list flex-s">
        <div
          @click.stop="showBoxInfoOnClick(vItem)"
          v-for="vItem in item.game_arena_box"
          :key="vItem.id"
          class="box-item flex-c">
          <img class="w100" :src="vItem.box.intact_cover" alt="" srcset="" />
        </div>
      </div>
      <div class="join-box flex-b">
        <div class="left flex-s">
          <CompCoin
            v-if="item.status === 0"
            style="color: #fae277"
            :num="item.total_bean" />
          <CompCoin
            v-if="item.status === 1"
            style="color: #fff"
            :num="item.total_bean" />
          <CompCoin
            v-if="item.status === 2"
            :styleList="[0.1, 0.1, 0.1, 0.02]"
            :num="item.total_bean" />
        </div>
        <div v-if="item.status === 0" class="right-text">加入</div>
        <div v-if="item.status === 1" class="right-text" style="color: #fff">
          观战
        </div>
        <div v-if="item.status === 2" class="join-icon"></div>
        <CompCoin
          v-if="item.status === 2"
          :styleList="[0.1, 0.1, 0.1, 0.02]"
          :num="item?.award_bean || '加载中...'" />
      </div>
    </div>
    <van-overlay :lock-scroll="false" :show="isShowBoxOdds">
      <div v-if="showItem" class="overlay-goods-detail">
        <div
          @click.stop="isShowBoxOdds = false"
          class="float-close-btn point"></div>
        <div class="header-title flex-c">
          {{ showItem.box.name }}
        </div>
        <div class="center-img-box flex-c">
          <img :src="showItem.box.intact_cover" alt="" />
        </div>
        <div class="main-box">
          <div class="odd-list-box flex-c">
            <div
              v-show="item.odds"
              v-for="item in retOddList"
              :key="item.id"
              class="odd-item flex-b">
              <div class="odd-item-in" :class="`odd-item-${item.level}`"></div>
              <div style="width: 0.16rem"></div>
              <div>{{ item.odds.toFixed(4) }}%</div>
            </div>
          </div>
          <div class="goods-list-box">
            <div class="header-bg flex-c">全部饰品</div>
            <div class="goods-list">
              <div
                v-for="vItem in showItem.box.contains"
                :key="vItem.id"
                class="goods-item"
                :class="`goods-item-${vItem.level}`">
                <div class="goods-item-top flex-b">
                  <div class="foo">{{ vItem.skins.dura_alias }}</div>
                  <div class="odd">{{ vItem.odds_percent }}</div>
                </div>
                <div class="goods-item-img flex-c">
                  <img :src="vItem.skins.cover" alt="" />
                </div>
                <div class="goods-item-name flex-c">
                  <div class="name ellipsis">
                    {{ vItem.skins.name }}
                  </div>
                </div>
                <div class="goods-item-bean flex-c">
                  <CompCoin
                    :styleList="[0.1, 0.1, 0.1, 0.02]"
                    :num="vItem.skins.bean" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    showBoxInfoOnClick(item) {
      console.log("每个箱子详情展示", item);
      this.showItem = item;
      this.isShowBoxOdds = true;
    },
  },
  computed: {
    retOddList() {
      let array = [
        { odds: 0, level: 1 },
        { odds: 0, level: 2 },
        { odds: 0, level: 3 },
        { odds: 0, level: 4 },
      ];
      array.forEach((item, index) => {
        console.log(1234111, item, this?.showItem);

        (this?.showItem?.box?.contains || []).forEach((vItem) => {
          if (item.level === vItem.level) {
            console.log(66666611, vItem);

            item.odds += parseFloat(vItem?.odds_percent.replace("%", ""));
          }
        });
      });
      return array;
    },
  },
  data() {
    return {
      isShowBoxOdds: false,
      showItem: null,
      // 对战类型筛选
      battleTypeFilterList: [
        { name: "全部", value: undefined },
        { name: "欧皇", value: "1" },
        { name: "非酋", value: "2" },
      ],
    };
  },
};
</script>

<style lang="scss">
// 等待中
.comp-battle-item {
  width: 1.6rem;
  min-height: 1rem;
  background: #69552d;
  margin-bottom: 0.15rem;
  flex-shrink: 0;
  padding: 0rem 0 0.05rem 0;
  @include bgImg("battle-item-bg");
  .overlay-goods-detail {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    top: 9vh;
    height: 91vh;
    left: 0;
    width: 100%;
    border-top: 0.02rem solid rgba(255, 215, 29, 1);
    padding: 0.18rem 0.2rem 0;
    .float-close-btn {
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      right: 0.12rem;
      top: 0.12rem;
      @include bgImg("close-btn");
    }
    .header-title {
      font-weight: 500;
      font-size: 0.16rem;
      margin-bottom: 0.12rem;
    }
    .center-img-box {
      margin-bottom: 0.2rem;
      img {
        width: 1.58rem;
      }
    }
    .main-box {
      .odd-list-box {
        margin-bottom: 0.12rem;
        flex-wrap: wrap;
        .odd-item {
          margin: 0 0.06rem;
          padding: 0rem 0rem;
          position: relative;
          font-size: 0.1rem;
          .odd-item-in {
            position: absolute;
            left: 0;
            top: 0;
            width: 0.32rem;
            height: 100%;
          }
          .odd-item-1 {
            @include bgImg("odd0");
          }
          .odd-item-2 {
            @include bgImg("odd1");
          }
          .odd-item-3 {
            @include bgImg("odd2");
          }
          .odd-item-4 {
            @include bgImg("odd3");
          }
        }
      }
      .goods-list-box {
        width: 100%;
        min-height: 1rem;
        background: #1d1300;
        border: 0.01rem solid #69552d;
        .header-bg {
          width: 100%;
          height: 0.36rem;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0.1rem;
          @include bgImg("battle-dialog-bg");
        }
        .goods-list {
          width: 100%;
          padding: 0 0.1rem;
          height: calc(100vh - 3.9rem);
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0.07rem;
          overflow-y: auto;
          align-content: flex-start;
          .goods-item {
            width: 0.99rem;
            height: 0.8rem;
            margin-bottom: 0.08rem;
            font-size: 0.1rem;
            padding: 0.03rem;
            .goods-item-top {
            }
            .goods-item-img {
              margin-top: -0.04rem;
              img {
                height: 0.48rem;
              }
            }
            .goods-item-name {
              padding: 0 0.08rem;
              margin-top: -0.04rem;
            }
            .goods-item-bean {
            }
          }
          .goods-item-1 {
            @include bgImg("openbox-bg0");
          }
          .goods-item-2 {
            @include bgImg("openbox-bg1");
          }
          .goods-item-3 {
            @include bgImg("openbox-bg2");
          }
          .goods-item-4 {
            @include bgImg("openbox-bg3");
          }
        }
      }
    }
  }
  .item-header {
    padding: 0 0.08rem;
    width: 100%;
    height: 0.24rem;
    font-size: 0.12rem;
    color: #ffe194;
    line-height: 0.24;
    margin-bottom: 0.03rem;
    @include bgImg("battle-item-bg-top");
    .left-text {
      color: #f64e37;
    }
    .left-text-end {
      color: #93d448;
    }
    .right-text {
    }
  }
  .item-name {
    width: 100%;
    height: 0.29rem;
    line-height: 0.29rem;
    text-align: center;
    font-weight: 500;
    font-size: 0.14rem;
    margin-bottom: 0.08rem;
    @include bgImg("name-bg");
  }
  .player-list {
    margin-bottom: 0.08rem;
    .player-item {
      width: 0.36rem;
      height: 0.36rem;
      position: relative;
      // @include bgImg("search-icon-plus");
      .player-item-img {
        width: 0.36rem;
        height: 0.36rem;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        .float-bg-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include bgImg("head-img-bg");
        }
      }
      .float-win-icon {
        width: 0.5rem;
        height: 0.24rem;
        position: absolute;
        left: -0.07rem;
        top: -0.08rem;
        // transform: translate(-50%, -50%);
        @include bgImg("battle-win-icon");
      }
    }
  }
  .box-list {
    padding: 0.02rem 0.05rem;
    margin-bottom: 0.12rem;
    background: #332a19;
    border: 0.01rem solid rgba(143, 108, 60, 0.3);
    overflow-x: auto;
    .box-item {
      width: 0.44rem;
      height: 0.44rem;
      margin-right: 0.04rem;
      flex-shrink: 0;
      // @include bgImg("search-icon-plus");
    }
  }
  .join-box {
    width: 100%;
    height: 0.3rem;
    font-weight: 500;
    font-size: 0.14rem;
    color: #fae277;
    padding: 0.08rem;
    @include bgImg("join-bg");
    .join-icon {
      width: 0.24rem;
      height: 0.24rem;
      @include bgImg("join-icon");
    }
  }
}

// 进行中
.list-item-ing {
  .join-box {
    @include bgImg("see-bg");
  }
}

// 已结束
.list-item-end {
  .join-box {
    @include bgImg("end-bg");
  }
}
</style>
